import {ApptSlot} from "@services/monolith/availability";
import {useCallback, useMemo} from "react";
import {
  SpecialtyId,
  covidTestingSpecialtyIds,
  virtualSpecialtyIds,
} from "src/constants/specialtyIds";
import {RegionSlug} from "src/store/types";

import {QueryState, useQueryController} from "../../../hooks/useQueryController";
import {compact} from "../../../utils/arrays";
import {fetchCachedSlots} from "../../../utils/fetchCachedSlot";
import {noOp} from "../../../utils/noOp";
import tee from "../../../utils/promise/tee";

export type LocSlotPair<T> = {
  location: T;
  soonestSlot: ApptSlot | null;
  specialtyId?: string;
  slots: ApptSlot[];
};

const specialtyPreferenceOrder: string[] = [
  ...covidTestingSpecialtyIds,
  SpecialtyId.URGENT_CARE,
  SpecialtyId.PRIMARY_CARE,
];

type LocationFragment = {
  id: string;
  specialtyIds: string[];
};

export type ApptReasonFragment = {
  id: string;
  specialtyIds: string[];
};

export type SlotParams = {
  days?: number;
  limit?: number;
  from?: number;
  to?: number;
  timezone?: string;
  patientId?: string; // this is only optional because we don't have it for unauthed users. If available, make sure to send it.
};

type Params<T extends LocationFragment> = {
  locations: T[];
  apptReason: ApptReasonFragment;
  selectedRegion?: RegionSlug;
  reportClinicOptions?: (locs: LocSlotPair<T>[]) => void;
  slotParams?: SlotParams;
  skip?: boolean;
};

const getInClinicSpecialtyIds = (ids: string[]): string[] =>
  ids.filter(
    specialtyId =>
      !virtualSpecialtyIds.includes(specialtyId as (typeof virtualSpecialtyIds)[number]),
  );

export const moveLocsWithoutAvailabilityToEnd = <T extends LocationFragment>(
  locSlotPairs: LocSlotPair<T>[],
): LocSlotPair<T>[] => {
  const divided = locSlotPairs.reduce(
    (acc, next) => {
      // @ts-expect-error TS2345: Argument of type 'LocSlotPair' is not assignable to parameter of type 'never'.
      acc[next.soonestSlot?.time ? "with" : "without"].push(next);
      return acc;
    },
    {with: [], without: []},
  );

  return [...divided.with, ...divided.without];
};

export const reducePreferedLocSlotPairs = <T>(
  locSlotPairs: LocSlotPair<T>[],
): LocSlotPair<T> | null =>
  // Check specialty ids in order to find the first matching loc with slot in set.
  specialtyPreferenceOrder.reduce<LocSlotPair<T> | null>(
    (maybeFoundLocSlotPair, preferredSpecialtyId) =>
      maybeFoundLocSlotPair || // if one has already been found, use that one. Otherwise...
      locSlotPairs.find(locSlotPair => locSlotPair.specialtyId === preferredSpecialtyId) ||
      null, // Try to find a loc that has a slot for this preferred specialtyId
    null, // default value of `maybeFoundLocSlotPair`
  ) ||
  locSlotPairs.sortBy(locSlotPair => locSlotPair.soonestSlot?.time)[0] ||
  null; // If none were found in specialty preference order, grab the one with the soonest slot.

const fetchAndFormatSlots = <T extends {id: string}>(
  location: T,
  specialtyId: string,
  reasonId: string,
  slotParams: SlotParams = {},
) =>
  fetchCachedSlots({
    locationId: location.id,
    specialtyId,
    reasonId,
    ...slotParams,
  }).then(slots => ({location, specialtyId, soonestSlot: slots?.[0] || null, slots}));

const generateLocSlotPairs = <T extends {id: string; specialtyIds: string[]}>({
  locations,
  apptReason,
  slotParams,
}: {
  locations: T[];
  apptReason: ApptReasonFragment;
  slotParams?: SlotParams;
}) => {
  const reasonSpecialtyIdsToCheck = getInClinicSpecialtyIds(apptReason.specialtyIds);
  return locations
    .map(location =>
      reasonSpecialtyIdsToCheck
        .filter(id => location.specialtyIds.includes(id))
        .map(specialtyId => fetchAndFormatSlots(location, specialtyId, apptReason.id, slotParams))
        .sequence()
        .then(reducePreferedLocSlotPairs),
    )
    .sequence()
    .then(compact)
    .then(moveLocsWithoutAvailabilityToEnd);
};

const generateBlankLocSlotPairs = <T extends {specialtyIds: string[]}>({
  locations,
  apptReason,
}: {
  locations: T[];
  apptReason: ApptReasonFragment | null;
}) => {
  const getFirstPreferredSpecialty = (location: T) =>
    specialtyPreferenceOrder.find(
      id => location.specialtyIds.includes(id) && apptReason?.specialtyIds.includes(id),
    );

  return locations.map(location => {
    const specialtyId = getFirstPreferredSpecialty(location);
    return {location, soonestSlot: null, specialtyId, slots: []};
  });
};

export const reduceLocSlotPairsToSoonestSlot = <T>(
  locSlotPairs: LocSlotPair<T>[],
): ApptSlot | null =>
  locSlotPairs.reduce((acc: ApptSlot | null, locSlotPair: LocSlotPair<T>) => {
    const isNextValid = Number.isInteger(locSlotPair?.soonestSlot?.time);
    const isFirstValid = acc === null;
    // @ts-expect-error TS2532, TS2532: Object is possibly 'undefined'.,  Object is possibly 'undefined'.
    const nextIsSooner = locSlotPair?.soonestSlot?.time < acc?.time;
    return isNextValid && (isFirstValid || nextIsSooner) ? locSlotPair.soonestSlot : acc;
  }, null);

export const maybePairLocsWithSlots = <T extends {id: string; specialtyIds: string[]}>(
  locations: T[],
  apptReason: ApptReasonFragment | null,
  slotParams?: SlotParams,
): Promise<LocSlotPair<T>[]> =>
  apptReason
    ? generateLocSlotPairs({locations, apptReason, slotParams})
    : Promise.resolve(generateBlankLocSlotPairs({locations, apptReason}));

export const usePairLocsWithSlotsQuery = <T extends LocationFragment>({
  locations,
  apptReason,
  reportClinicOptions = noOp,
  slotParams,
  skip,
}: Params<T>): QueryState<LocSlotPair<T>[]> => {
  const defaultValue = useMemo(() => [], []);

  const fn = useCallback(
    () => maybePairLocsWithSlots(locations, apptReason, slotParams).then(tee(reportClinicOptions)),
    [apptReason, locations, reportClinicOptions, slotParams],
  );

  return useQueryController<LocSlotPair<T>[]>({
    fn,
    skip,
    initialValue: defaultValue,
    cacheKey: JSON.stringify({id: apptReason.id, slotParams, locations: locations.map(l => l.id)}),
  });
};
